/* Reset styles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

button {
  cursor: pointer;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--color) !important;
  caret-color: var(--color);
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-SemiBold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-ExtraBold.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neuropolitical";
  src: url(./fonts/neuropolitical-rg.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sansation";
  src: url(./fonts/Sansation_Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
